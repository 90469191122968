import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { getCatalogueFilteredData } from '../../services/catalogue.service';
import './selectDataSet.scss';
import * as api from "../../services/middleware/api.middleware"

interface ExampleComponentProps {
  showModal: boolean;
  onClose: () => void;
  setData: any;
  selectedItems: any;
  formattedString: any;
  multipleSelection: string[];
  setMultipleSelection: any;
  selectedEndpoints: string[];
  setSelectedEndpoints: any;
  setShowErrorModal: any;
  currentPage:number
}

const SelectedDataSet: React.FC<ExampleComponentProps> = ({ 
  showModal, 
  onClose, 
  setData, 
  selectedItems, 
  formattedString, 
  multipleSelection, 
  setMultipleSelection, 
  selectedEndpoints, 
  setSelectedEndpoints, 
  setShowErrorModal,
  currentPage
}) => {
  const [dataSourceList, setDataSourceList] = useState<{ data_source_name: string, endpoints: string[] }[]>([]);
  const [allRelatedEndpoints, setAllRelatedEndpoints] = useState<string[]>([]);

  const getEnvironmentId = () => {
    return localStorage.getItem("environment_id");
  };
  const getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    return {
        Authorization: `Bearer ${accessToken}`,
    };
  };

  useEffect(() => {
    if (showModal) {
      const response = api.get<any>(
        `environments/${getEnvironmentId()}/data_sources`,
        getAccessToken()
      );
        
      response
        .then(data => {
          const endpoints: { data_source_name: string, endpoints: string[] }[] = [];
          data.forEach((dataSource: any) => {
            const endpointNames = dataSource.source_entity.map((entity: any) => entity.source_entity_name);
            endpoints.push({ data_source_name: dataSource.data_source_name, endpoints: endpointNames });
          });
          setDataSourceList(endpoints);
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        });
    }
  }, [showModal]);

  const handleDataSourceSelect = (dataSource: string) => {
    setMultipleSelection((prev: any) => {
      if (prev.includes(dataSource)) {
        return prev.filter((item: any) => item !== dataSource);
      } else {
        return [...prev, dataSource];
      }
    });
  };

  const handleEndpointSelect = (endpoint: string) => {
    setSelectedEndpoints((prevList: any) => {
      if (prevList.includes(endpoint)) {
        return prevList.filter((item: any) => item !== endpoint);
      } else {
        return [...prevList, endpoint];
      }
    });
  };

  useEffect(() => {
    const selectedEndpointsVar = dataSourceList
      .filter(dataSource => multipleSelection.includes(dataSource.data_source_name))
      .flatMap(dataSource => dataSource.endpoints);
  
    if (multipleSelection.length <=0 ){
      setAllRelatedEndpoints([]);
      setSelectedEndpoints([]); 
    }else if(multipleSelection.length >= 0){
      setAllRelatedEndpoints(selectedEndpointsVar);  
    }
  }, [multipleSelection, dataSourceList]);

  const fetchResults = async () => {
    try {
      getCatalogueFilteredData(selectedItems, formattedString, multipleSelection, selectedEndpoints, currentPage)
        .then((res: any) => {
          if (res.data.length === 0) {
            setShowErrorModal(true);
            setMultipleSelection([]);
          } else {
            setData(res);
          }
        });
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  const handleSave = () => {
    fetchResults();
    onClose();
  };

  return (
    <Modal show={showModal} onHide={onClose} size="lg" className="selected-data-set">
      <Modal.Header closeButton>
        <Modal.Title>Select Data Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="form-container">
          <div className="data-source-list">
            <h5>Select Data Sources</h5>
            <ul>
              {dataSourceList.map((dataSource, index) => (
                <li key={index}>
                  <Form.Check
                    type="checkbox"
                    checked={multipleSelection.includes(dataSource.data_source_name)}
                    onChange={() => handleDataSourceSelect(dataSource.data_source_name)}
                    className="checkbox"
                  />
                  <span>{dataSource.data_source_name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="endpoint-list">
            <h5>Select EndPoints</h5>
            {multipleSelection.length === 0 ? (
              <p className="no-data">No data source selected</p>
            ) : (
              <ul>
                {allRelatedEndpoints.map((endpoint, index) => (
                  <li key={index}>
                    <Form.Check
                      type="checkbox"
                      checked={selectedEndpoints.includes(endpoint)}
                      onChange={() => handleEndpointSelect(endpoint)}
                      className="checkbox"
                    />
                    <span>{endpoint}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button variant="secondary" onClick={onClose}>Close</Button>
        <Button className="btn-save" onClick={handleSave} disabled={multipleSelection.length <= 0}>Save changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectedDataSet;