import React, { Suspense, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavHeader from "./components/Header/NavHeader";
import NavRoutes from "./components/Header/NavRoutes";
import EndPoints from "./containers/EndPoints";

import Login from "./components/Login/loginForgot";
import Authenticator from "./containers/Authenticator/Authenticator/Authenticator";
import EmailVerification from "./containers/Authenticator/EmailVerification/EmailCodeVerification";
import CodeVerification from "./containers/Authenticator/CodeVerifiaction/CodeVerification";
import SetUpEnvironmentCard from "../src/containers/Onboard/SetUpEnvironmentCard";
import ForgotPassword from "./components/Login/loginForgot/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Login/loginForgot/ResetPassword/ResetPassword";
import MicrosoftAuthentication from "./containers/Authenticator/MicrosoftAuthentication/MicrosoftAuthentication";
import NotFound from "./components/Page404/NotFound";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import ChangePassword from "./components/Login/loginForgot/ChangePassword/ChangePassword";
import SupportPage from "./containers/Support/Support";
import Dashboard from "./containers/Dashboard";
import EnsembleModelling from "./containers/Ensemble Modelling";
// import Structure from "./containers/Structure";
// import CustomTable from "./containers/catalogue";
import NewHome from "./containers/NewHome";
import { ReactFlowProvider } from "reactflow";
import CustomTable from "./containers/catalogue";

const EnvironmentDetails = React.lazy(
  () => import("./containers/EnvironmentDetails")
);
const DataSources = React.lazy(() => import("./containers/DataSources"));
const ImportDataSources = React.lazy(
  () => import("./containers/DataSources/ImportDataSources")
);
const UserAdminPage = React.lazy(() => import("./containers/UserAdminPage"));
const CustomPlugInManagement = React.lazy(
  () => import("./containers/CustomPlugInManagement")
);

const DataLineage = React.lazy(() => import("./containers/DataLineage"));
const ImportDataModel = React.lazy(
  () => import("./containers/ImportDataModel")
);
const JobManagement = React.lazy(() => import("./containers/JobManagement"));
const JobDetails = React.lazy(
  () => import("./containers/JobManagement/JobDetails")
);
const JobDependency = React.lazy(() => import("./containers/JobDependency"));

function App() {
  const [updateEnvironmentd, setUpdateEnvironmentd] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isLoggedInHandler = () => {
    setIsLoggedIn(true);
  };
  const updateEnvironmentsDetails = (res: boolean) => {
    setUpdateEnvironmentd(res);
  };

  return (
    <div className="wade-portal">
      <BrowserRouter>
        <div className="app-container">
        {localStorage.getItem("isLoggedIn") === "true" &&
          <NavHeader
            updateEnvironmentsDetails={updateEnvironmentsDetails}
            isLoggedIn={isLoggedIn}
          />
        }
          <div className="sidebar-app-container">
          {localStorage.getItem("isLoggedIn") === "true" &&
            <div className="sidebar-container">
              <NavRoutes
                updateEnvironmentsDetails={updateEnvironmentsDetails}
                isLoggedIn={isLoggedIn}
              />
            </div>
            }
            <div className="routes-container">
              <Routes>
                <Route
                  path="/"
                  element={
                    localStorage.getItem("isLoggedIn") === "true" ? (
                      <Navigate to="/home" replace />
                    ) : (
                      <Login />
                    )
                  }
                />

                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/reset_password" element={<ResetPassword />} />
                <Route path="/change_password" element={<ChangePassword />} />

                <Route
                  path="/onboard"
                  element={
                    <ProtectedRoute>
                      <SetUpEnvironmentCard
                        isLoggedInHandler={isLoggedInHandler}
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                   <ProtectedRoute>
                     <ReactFlowProvider>
                       <NewHome/>
                     </ReactFlowProvider>
                   </ProtectedRoute>
                 </Suspense>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/importDataModel"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <ImportDataModel />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/ensembleModelling"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <EnsembleModelling />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/workspaceDetails"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <EnvironmentDetails
                          updateEnvironmentd={updateEnvironmentd}
                        />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/dataSources"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <DataSources />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/importaDataSource"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <ImportDataSources />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/endPoints"
                  element={
                    <ProtectedRoute>
                      <EndPoints />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/adminPage"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <UserAdminPage />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route path="/authenticator" element={<Authenticator />} />
                <Route
                  path="/emailVerification"
                  element={<EmailVerification />}
                />
                <Route
                  path="/microsoftVerification"
                  element={<MicrosoftAuthentication />}
                />
                <Route
                  path="/codeVerification"
                  element={
                    <CodeVerification
                      length={6}
                      isLoggedInHandler={isLoggedInHandler}
                    />
                  }
                />
                <Route
                  path="/jobManagement"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <JobManagement />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/jobManagement/:id"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <JobDetails />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/customPlugin"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <CustomPlugInManagement />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/jobDependency"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <JobDependency page={false} />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/jobTaskGroupDependency/:refreshJobId"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <JobDependency page={true} />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/dataLineage"
                  element={
                    <Suspense fallback={<div> Please Wait... </div>}>
                      <ProtectedRoute>
                        <DataLineage />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <ProtectedRoute>
                      <SupportPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dataCatalog"
                  element={
                    <ProtectedRoute>
                      <CustomTable />
                    </ProtectedRoute>
                  }
                />
                {localStorage.getItem("isLoggedIn") === "true" && (
                  <Route path="*" element={<NotFound />} />
                )}
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
