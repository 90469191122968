import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavRoutes.scss";
import { logout } from "../../services/logout.service";
import { Navbar, Nav, Container } from "react-bootstrap";
import { TiHome } from "react-icons/ti";
import { RiGovernmentLine } from "react-icons/ri";
import { PiSignOut } from "react-icons/pi";
import Group2357 from "../../assets/images/svg/Group2357";
import Group2417 from "../../assets/images/svg/Group2417";
import { VscBook } from "react-icons/vsc";

import { FaPlug } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";


import { RiDashboardFill } from "react-icons/ri";
import { FaDatabase } from "react-icons/fa";
function NavRoutes(props: any) {
  const { isLoggedIn } = props;

  const location = useLocation();
  const onSignOutHandler = () => {
    logout();
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("access_token");
    localStorage.removeItem("environment_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("email");
    localStorage.removeItem("refresh_token");
  };

  return (
    <div className="side-navbar-routes-container">
      {(isLoggedIn || localStorage.getItem("isLoggedIn") === "true") && (
        <Navbar className="nav-sidebar-outer-container">
          <Container fluid className="custom-fluid">
            <Navbar.Collapse className="custom-navbar">
              <Nav className="nav-item-inner-container">
                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/home"
                    active={location.pathname === "/home"}
                  >
                    <div className="route-container">
                      <span className="icon-container">
                        <TiHome className="route-icon" />
                      </span>
                      <span>Home</span>
                    </div>
                  </Nav.Link>
                </div>

                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/dashboard"
                    active={location.pathname === "/dashboard"}
                  >
                    <div className="route-container">
                      <span className="icon-container">
                        <RiDashboardFill className="route-icon" />
                      </span>
                      <span>Dashboard</span>
                    </div>
                  </Nav.Link>
                </div>

                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/dataSources"
                    active={location.pathname === "/dataSources"}
                  >
                    <div className="route-container">
                      <span id="dbSource">
                        <FaDatabase size={30} color="#000" />
                      </span>
                      <span id="data-model-automation-text" style={{marginLeft:"16px"}}>Data Sources Management</span>
                    </div>
                  </Nav.Link>
                </div>
                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/importDataModel"
                    active={
                      ["/importDataModel", "/ensembleModelling"].indexOf(
                        location.pathname
                      ) > -1
                    }
                    id="navbarScrollingDropdown"
                  >
                    <div className="route-container">
                      <span id="group2417">
                        <Group2417 viewBox="0 0 58 58" />
                      </span>
                      <span id="data-model-automation-text" style={{marginLeft:"5px"}}>
                        Data Model Automation
                      </span>
                    </div>
                  </Nav.Link>
                </div>
                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/jobDependency"
                    active={
                      [
                        "/jobManagement",
                        "/jobDependency",
                      ].indexOf(location.pathname) > -1
                    }
                    id="navbarScrollingDropdown"
                  >
                    <div className="route-container">
                      <span id="group2357">
                        <Group2357 viewBox="0 0 58 58" />
                      </span>
                      <span id="orchestration-text">Orchestration</span>
                    </div>
                  </Nav.Link>
                </div>
                <div id="navBar_dashboard_users">
                  <Nav.Link
                    as={Link}
                    to="/dataLineage"
                    active={["/dataLineage"].indexOf(location.pathname) > -1}
                    id="navbarScrollingDropdown"
                  >
                    <div className="route-container">
                      <span className="icon-container">
                        <RiGovernmentLine className="route-icon" />
                      </span>
                      <span>Governance</span>
                    </div>
                  </Nav.Link>
                </div>
                <div className="sign-out-container-v2">
                 <Nav.Link
                    as={Link}
                    to="/dataCatalog"
                    >
                      <div className="route-container">
                        <span className="icon-container">
                          <VscBook size={30} style={{marginRight:"16px",}} color="white"/>
                        </span>
                        <span style={{color:"white"}}>Data Catalog</span>
                      </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/customPlugin"
                    >
                      <div className="route-container">
                        <span className="icon-container">
                          <FaPlug size={30} style={{marginRight:"16px",}} color="white"/>
                        </span>
                        <span style={{color:"white"}}>Plug-in Management</span>
                      </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/adminPage"
                    >
                      <div className="route-container">
                        <span className="icon-container">
                          <RiAdminLine size={30} className="route-icon" color="white"/>
                        </span>
                        <span style={{color:"white", textAlign:"left"}}>Admin</span>
                      </div>
                  </Nav.Link>
                </div>
                <div className="sign-out-container">
                  <Nav.Link id="sign-out" onClick={onSignOutHandler} href="/">
                    <div className="route-container">
                      <span className="icon-container">
                        <PiSignOut className="route-icon" />
                      </span>
                      <span>Sign Out</span>
                    </div>
                  </Nav.Link>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </div>
  );
}

export default NavRoutes;
