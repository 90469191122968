import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./MicrosoftAuthentication.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button, Modal } from "react-bootstrap";
import QRCode from "qrcode.react";
import { cn } from "../../../helpers";
import CodeVerificationDialog from "../CodeVerifiaction/CodeVerificationDialog";
import { setIsAuthorized } from "../../../redux/login/actions";
import VerifiedAccount from "../../../components/Login/Message/VerifiedAccount/VerifiedAccount";
import { readUsers } from "../../../services/readUsers.service";

export interface MicrosoftAuthenticationProps {
  show: boolean;
  email: string;
  password: string;
  user_id: number;
  onHide: any;
  qrValue: string;
}

const MicrosoftAuthenticatorDialog = (props: MicrosoftAuthenticationProps) => {
  const { show, onHide, email, password, user_id, qrValue } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeVerificationDialog, setCodeVerificationDialog] = useState(false);
  const [onSubmit, SetOnSubmit] = useState(false);

  const onGetCodeButtonClickHandler = () => {
    onHide();
    setCodeVerificationDialog(true);
  };

  const onDoneButtonClick = () => {
    readUsers(email, password).then((response) => {
      if (response.is_verified === false) {
        navigate("/");
        SetOnSubmit(false);
      } else {
        dispatch(setIsAuthorized(true));
        navigate("/onboard");
      }
    });
  };

  const onCloseIconClick = () => {
    SetOnSubmit(false);
    dispatch(setIsAuthorized(true));
    navigate("/onboard");
  };

  const handleSuccessSubmit = () => {
    setCodeVerificationDialog(false);
    SetOnSubmit(!onSubmit);
  };

  return (
    <div>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.msAuthenticatorMain}
      >
        <Modal.Body>
          <div
            className={cn(
              styles.msAuthenticatorMain,
              "d-flex justify-content-center"
            )}
          >
            <div
              className={cn(
                styles.msAuthenticatorMainBody,
                "d-flex justify-content-center align-items-center"
              )}
            >
              <div>
                <span
                  className={cn(
                    styles.msAuthenticatorBodyHead,
                    "d-flex justify-content-center"
                  )}
                >
                  <img src={wadeIcon} />
                </span>
                <span className={styles.msAuthenticatorBodyHeadTitle}>
                  <h6>Microsoft Authentification</h6>
                </span>
                <div
                  className={cn(
                    styles.msAuthenticatorBodyOptionEmail,
                    "d-flex justify-content-center"
                  )}
                >
                  <div className={styles.msAuthenticatorBodyEmail}>
                    <span className={styles.msAuthenticatorBodyHead}>
                      <div className={styles.msAuthenticatorBodyQRCode}>
                        <QRCode value={qrValue} className={styles.qrcode} />
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  className={cn(
                    styles.msAuthenticatorBodyBTNBody,
                    "d-flex justify-content-center"
                  )}
                >
                  <Button
                    className={styles.msAuthenticatorBodyBTN}
                    onClick={onGetCodeButtonClickHandler}
                  >
                    GET CODE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {codeVerificationDialog && (
        <CodeVerificationDialog
          show={codeVerificationDialog}
          handleSuccessSubmit={handleSuccessSubmit}
          email={email}
          password={password}
          user_id={user_id}
          verifyType="microsoft"
          length={6}
        />
      )}
      {onSubmit && (
        <VerifiedAccount
          show={onSubmit}
          onHide={onCloseIconClick}
          onDoneButtonClick={onDoneButtonClick}
        />
      )}
    </div>
  );
};
export default MicrosoftAuthenticatorDialog;
