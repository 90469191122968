import {
  IGetCustomPlugInInterface,
  IPostCustomPlugInInterface,
  IPutCustomPlugInInterface,
} from "../interfaces/customPlugIn.interface";
import * as api from "./middleware/api.middleware";

const serviceUrl = "custom_plugins";

const getCustomerId = () => {
  return localStorage.getItem("customer_id");
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export function getCustomPlugInDetails() {
  const getResponse = api.get<IGetCustomPlugInInterface[]>(
    `customers/${getCustomerId()}/${serviceUrl}`,
    getAccessToken()
  );
  return getResponse;
}

export function getCustomPlugInType() {
  const getResponse = api.get<any>(`plugin_types`, getAccessToken());
  return getResponse;
}

export function postCustomPlugInDetails(data: any) {
  const getResponse = api.post<IPostCustomPlugInInterface>(
    `customers/${getCustomerId()}/${serviceUrl}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function putCustomPlugInDetails(data: any, id: number) {
  const getResponse = api.put<IPutCustomPlugInInterface>(
    `customers/${getCustomerId()}/${serviceUrl}/${id}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteCustomPlugInDetails(id: number) {
  const getResponse = api.del<any>(
    `customers/${getCustomerId()}/${serviceUrl}/${id}`,
    null,
    getAccessToken()
  );
  return getResponse;
}
