import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteConfirmationModalProps {
    show: boolean;
    handleClose: () => void;
    handleDelete: (view:any) => void;
    viewName?: string; // optional string, as it may be undefined initially
}


const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({ show, handleClose, handleDelete, viewName }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header style={{background:"#dee2e6"}}>
                <Modal.Title style={{background:"#dee2e6"}}>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background:"#dee2e6"}}>
                Are you sure you want to delete the view: <strong>{viewName}</strong>?
            </Modal.Body>
            <Modal.Footer style={{background:"#dee2e6"}}>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;