import React from "react";
import styles from "./MicrosoftAuthentication.module.scss";
import wadeIcon from "../../../components/Icons/wadeIcon.svg";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getEnable2FAQRCode } from "../../../services/Enable2FA.service";
import QRCode from "qrcode.react";
import { cn } from "../../../helpers";

const MicrosoftAuthentication = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [qrvalue, setQrValue] = React.useState("");

  const onGetCodeButtonClickHandler = () => {
    navigate("/codeVerification", {
      state: {
        email: location.state.email,
        password: location.state.password,
        user_id: location.state.user_id,
        verifyType: "microsoft",
      },
    });
  };

  React.useEffect(() => {
    getEnable2FAQRCode(
      4,
      location?.state?.email || "",
      location?.state?.password || ""
    ).then((res: any) => setQrValue(res.provisioning_uri));
  }, []);

  return (
    <div
      className={cn(
        styles.msAuthenticatorMain,
        "d-flex justify-content-center"
      )}
    >
      <div
        className={cn(
          styles.msAuthenticatorMainBody,
          "d-flex justify-content-center align-items-center"
        )}
      >
        <div>
          <span
            className={cn(
              styles.msAuthenticatorBodyHead,
              "d-flex justify-content-center"
            )}
          >
            <img src={wadeIcon} />
          </span>
          <span className={styles.msAuthenticatorBodyHeadTitle}>
            <h6>Microsoft Authentification</h6>
          </span>
          <div
            className={cn(
              styles.msAuthenticatorBodyOptionEmail,
              "d-flex justify-content-center"
            )}
          >
            <div className={styles.msAuthenticatorBodyEmail}>
              <span className={styles.msAuthenticatorBodyHead}>
                <div className={styles.msAuthenticatorBodyQRCode}>
                  <QRCode value={qrvalue} className={styles.qrcode} />
                </div>
              </span>
            </div>
          </div>
          <div
            className={cn(
              styles.msAuthenticatorBodyBTNBody,
              "d-flex justify-content-center"
            )}
          >
            <Button
              className={styles.msAuthenticatorBodyBTN}
              onClick={onGetCodeButtonClickHandler}
            >
              GET CODE
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MicrosoftAuthentication;
