import {
  IGetCustomPlugInTask,
  IGetJobManagementInterface,
  IGetJobTask,
  IGetTargetEntityDataMart,
  IGetTargetEntityRawDataVault,
  IPostJobManagementInterface,
} from "../interfaces/jobManagement.interfaces";
import * as api from "./middleware/api.middleware";

const serviceUrl = "environments";

const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

const getEnvironmentId = () => {
  return localStorage.getItem("environment_id");
};

export function createNewJob(data: any) {
  const start_time = data.start_date
    ? `${data.start_date}T${data.start_time}${data.start_time_zone}`
    : null;
  const end_time = data.end_date
    ? `${data.end_date}T${data.end_time}${data.end_time_zone}`
    : null;
  data = {
    ...data,
    start_time: start_time,
    end_time: end_time,
    start_date: undefined,
    end_date: undefined,
  };
  const getResponse = api.post<IPostJobManagementInterface[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function getJobDetails() {
  const getResponse = api.get<IGetJobManagementInterface[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs`,
    getAccessToken()
  );
  return getResponse;
}

export function deleteJobDetails(jobId: number) {
  const getResponse = api.del<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function updateJobDetails(data: any) {
  const start_time =
    data.start_date && data.schedule_frequency != "On Demand"
      ? `${data.start_date}T${data.start_time}`
      : null;
  const end_time =
    data.end_date && data.showDatePicker
      ? `${data.end_date}T${data.end_time}`
      : null;
  data = {
    ...data,
    start_time: start_time,
    end_time: end_time,
    schedule_frequency: data.showScheduleDetails
      ? data.schedule_frequency
      : "On Demand",
    recurrence_units:
      data.schedule_frequency != "On Demand" ? data.recurrence_units : "MIN",
    recurrence_frequency:
      data.schedule_frequency != "On Demand" ? data.recurrence_frequency : 0,
    start_date: undefined,
    end_date: undefined,
  };
  const getResponse = api.put<IPostJobManagementInterface[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${data.job_id}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function createNewJobTaskGroup(data: any, jobId: any) {
  const layerId =
    data.layer_id === "Bronze"
      ? 1
      : data.layer_id === "Silver"
      ? 2
      : data.layer_id === "Gold"
      ? 3
      : null;
  let updatedData = { ...data, layer_id: layerId };
  const getResponse = api.post<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups`,
    updatedData,
    getAccessToken()
  );
  return getResponse;
}

export function getJobTaskGroupDetails(jobId: any) {
  const getResponse = api.get<IGetJobManagementInterface[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups`,
    getAccessToken()
  );
  return getResponse;
}

export function deleteJobTaskGroupDetails(
  jobId: number,
  jobTaskGroupId: string
) {
  const getResponse = api.del<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function updateJobTaskGroupDetails(
  data: any,
  jobId: number,
  jobTaskGroupId: number
) {
  const layerId =
    data.layer_id === "Bronze"
      ? 1
      : data.layer_id === "Silver"
      ? 2
      : data.layer_id === "Gold"
      ? 3
      : null;
  let updatedData = { ...data, layer_id: layerId };
  const getResponse = api.put<IPostJobManagementInterface[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}`,
    updatedData,
    getAccessToken()
  );
  return getResponse;
}

export function getTargetEntityDataMart() {
  const getResponse = api.get<IGetTargetEntityDataMart[]>(
    `${serviceUrl}/${getEnvironmentId()}/target_entity/data_mart`,
    getAccessToken()
  );
  return getResponse;
}

export function getTargetEntityRawDataVault() {
  const getResponse = api.get<IGetTargetEntityRawDataVault[]>(
    `${serviceUrl}/${getEnvironmentId()}/target_entity/raw_data_vault`,
    getAccessToken()
  );
  return getResponse;
}

export function getJobTaskForDataSources(
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.get<IGetJobTask[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/data_sources`,
    getAccessToken()
  );
  return getResponse;
}

export function getJobTaskForDataMart(jobId: number, jobTaskGroupId: number) {
  const getResponse = api.get<IGetJobTask[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/data_mart`,
    getAccessToken()
  );
  return getResponse;
}
export function getJobTaskForRawDataVault(
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.get<IGetJobTask[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/raw_data_vault`,
    getAccessToken()
  );
  return getResponse;
}

export function getJobTaskForCustomPlugIn(
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.get<IGetCustomPlugInTask[]>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/custom_plugin`,
    getAccessToken()
  );
  return getResponse;
}

export function postJobTaskForRawDataVault(
  data: any,
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.post<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/raw_data_vault`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function postJobTaskForDataMart(
  data: any,
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.post<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/data_mart`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function postJobTaskForDataSources(
  data: any,
  jobId: number,
  jobTaskGroupId: number
) {
  const getResponse = api.post<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/data_sources`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function postJobTaskForCustomPlugIn(
  data: any,
  jobId: number,
  jobTaskGroupId: number
) {
  const queryParams: any = {
    custom_plugin_execution_sequence: data[0].plugin_type,
  };

  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  const getResponse = api.post<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/custom_plugin?${queryString}`,
    data,
    getAccessToken()
  );
  return getResponse;
}

export function deleteEntityDetails(
  jobId: number,
  jobTaskGroupId: number,
  JobTaskId: number
) {
  const getResponse = api.del<any>(
    `${serviceUrl}/${getEnvironmentId()}/jobs/${jobId}/job_task_groups/${jobTaskGroupId}/job_tasks/${JobTaskId}`,
    null,
    getAccessToken()
  );
  return getResponse;
}

export function getJobMonitorDetails() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/job_execution_monitor`,
    getAccessToken()
  );
  return getResponse;
}

export function getCurrentJobExecutionDetails() {
  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/current_job_execution_details`,
    getAccessToken()
  );
  return getResponse;
}

export function getJobExecutionStatus(
  jobExecutionStartDate: any,
  jobExecutionEndDate: any
) {
  const queryParams: any = {
    start_date: jobExecutionStartDate,
    end_date: jobExecutionEndDate,
  };

  const queryString = Object.keys(queryParams)
    .map((key) => {
      if (encodeURIComponent(queryParams[key]) !== "null") {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          queryParams[key]
        )}`;
      } else {
        return null;
      }
    })
    .filter((value: any) => value != null)
    .join("&");

  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/job_execution_status?${queryString}`,
    getAccessToken()
  );
  return getResponse;
}

export function getJobExecutionHistory(
  jobDurationStartDate: any,
  jobDurationEndDate: any
) {
  const queryParams: any = {
    start_date: jobDurationStartDate,
    end_date: jobDurationEndDate,
  };

  const queryString = Object.keys(queryParams)
    .map((key) => {
      if (encodeURIComponent(queryParams[key]) !== "null") {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          queryParams[key]
        )}`;
      } else {
        return null;
      }
    })
    .filter((value: any) => value != null)
    .join("&");

  const getResponse = api.get<any[]>(
    `${serviceUrl}/${getEnvironmentId()}/job_execution_history?${queryString}`,
    getAccessToken()
  );

  return getResponse;
}
